<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i>月份价格
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <div class="mr9">
                    <el-button
                        type="primary"
                        icon="el-icon-plus"
                        class="handle-del mr10"
                        @click="addguestroommetnod"
                    >新增月份价格</el-button>
                </div>
                <div class="mr9">
                    <el-button
                        type="primary"
                        icon="el-icon-back"
                        class="handle-del mr10"
                        v-on:click="back"
                    >返回</el-button>
                </div>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="month" label="月份" align="center"></el-table-column>
                <el-table-column prop="old_price" label="原价" align="center"><template #default="scope">￥{{ scope.row.old_price }}</template></el-table-column>
                <el-table-column prop="price" label="现价" align="center"><template #default="scope">￥{{ scope.row.price }}</template></el-table-column>
                <el-table-column prop="sun" label="星期日" align="center"><template #default="scope">￥{{ scope.row.sun }}</template></el-table-column>
                <el-table-column prop="mon" label="星期一" align="center"><template #default="scope">￥{{ scope.row.mon }}</template></el-table-column>
                <el-table-column prop="tues" label="星期二" align="center"><template #default="scope">￥{{ scope.row.tues }}</template></el-table-column>
                <el-table-column prop="wed" label="星期三" align="center"><template #default="scope">￥{{ scope.row.wed }}</template></el-table-column>
                <el-table-column prop="thur" label="星期四" align="center"><template #default="scope">￥{{ scope.row.thur }}</template></el-table-column>
                <el-table-column prop="fri" label="星期五" align="center"><template #default="scope">￥{{ scope.row.fri }}</template></el-table-column>
                <el-table-column prop="sat" label="星期六" align="center"><template #default="scope">￥{{ scope.row.sat }}</template></el-table-column>
                <el-table-column label="操作" width="280" align="center">
                    <template #default="scope">
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" class="red" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :page-size="query.pageSize"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
            
            <!-- 添加弹出框 -->
            <el-dialog title="添加月份价格" v-model="addVisible" width="40%">
                <el-form :model="addmonthData" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
                     <el-form-item label="月份" prop="month">
                         <el-input v-model="addmonthData.month" placeholder="请输入月份"></el-input>
                    </el-form-item>
                     <el-form-item label="原价" prop="old_price">
                         <el-input v-model="addmonthData.old_price" placeholder="请输入原价"></el-input>
                    </el-form-item>
                     <el-form-item label="月份价格" prop="price">
                         <el-input v-model="addmonthData.price" placeholder="请输入月份价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期一价格" prop="mon">
                         <el-input v-model="addmonthData.mon" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期二价格" prop="tues">
                         <el-input v-model="addmonthData.tues" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期三价格" prop="wed">
                         <el-input v-model="addmonthData.wed" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期四价格" prop="thur">
                         <el-input v-model="addmonthData.thur" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期五价格" prop="fri">
                         <el-input v-model="addmonthData.fri" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期六价格" prop="sat">
                         <el-input v-model="addmonthData.sat" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期天价格" prop="sun">
                         <el-input v-model="addmonthData.sun" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addmenuFun('addmonthData')">确定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="40%">
            <el-form :model="editmonthData" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
                     <el-form-item label="月份" prop="month">
                         <el-input v-model="editmonthData.month" placeholder="请输入月份"></el-input>
                    </el-form-item>
                     <el-form-item label="原价" prop="old_price">
                         <el-input v-model="editmonthData.old_price" placeholder="请输入原价"></el-input>
                    </el-form-item>
                     <el-form-item label="月份价格" prop="price">
                         <el-input v-model="editmonthData.price" placeholder="请输入月份价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期一价格" prop="mon">
                         <el-input v-model="editmonthData.mon" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期二价格" prop="tues">
                         <el-input v-model="editmonthData.tues" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期三价格" prop="wed">
                         <el-input v-model="editmonthData.wed" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期四价格" prop="thur">
                         <el-input v-model="editmonthData.thur" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期五价格" prop="fri">
                         <el-input v-model="editmonthData.fri" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期六价格" prop="sat">
                         <el-input v-model="editmonthData.sat" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                     <el-form-item label="星期天价格" prop="sun">
                         <el-input v-model="editmonthData.sun" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit('editmonthData')">确定</el-button>
                    </span>
                </template>
        </el-dialog>
    </div>
</template>

<script>
import { get_room_price_list,post_room_price_list } from "../../api/index";
export default {
    name: "basetable",
    data() {
        return {
            query: {
                room_id: this.$route.query.id,
            },
            addmonthData:{
               type:"add",
               room_id:this.$route.query.id,
               month:this.month,
               old_price:this.old_price,
               price:this.price,
               mon:this.mon,
               tues:this.tues,
               wed:this.wed,
               thur:this.thur,
               fri:this.fri,
               sat:this.sat,
               sun:this.sun
            },
            editmonthData:{
               month:this.month,
               old_price:this.old_price,
               price:this.price,
               mon:this.mon,
               tues:this.tues,
               wed:this.wed,
               thur:this.thur,
               fri:this.fri,
               sat:this.sat,
               sun:this.sun
            },
            editID:"",
               // 表单验证
            rules: {
               month: [
                    { required: true, message: "请输入月份", trigger: "blur" },
               ],
               old_price: [
                    { required: true, message: "请输入原价", trigger: "blur" },
               ],
               price: [
                    { required: true, message: "请输入月份价格", trigger: "blur" },
               ],
               mon: [
                    { required: true, message: "请输入周一价格", trigger: "blur" },
               ],
               tues: [
                    { required: true, message: "请输入周二价格", trigger: "blur" },
               ],
               wed: [
                    { required: true, message: "请输入周三价格", trigger: "blur" },
               ],
               thur: [
                    { required: true, message: "请输入周四价格", trigger: "blur" },
               ],
               fri: [
                    { required: true, message: "请输入周五价格", trigger: "blur" },
               ],
               sat: [
                    { required: true, message: "请输入周六价格", trigger: "blur" },
               ],
               sun: [
                    { required: true, message: "请输入周天价格", trigger: "blur" },
               ],
            },
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            addVisible:false
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        
        getData() {
            get_room_price_list(this.query).then(res => {
               
                this.tableData = res.data;
                this.pageTotal = res.data.count;
            });
        },
        // 删除操作
        handleDelete(index,row) {
            var addhotelData = {
                type: "delete",
                id: row.id,
            };
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            }).then(() => {
            post_room_price_list(addhotelData).then((res) => {
                if (res.code == 1) {
                    this.$message.success(res.msg);
                    this.getData();
                }else{
                    this.$message.error(res.msg);

                }
            });
          this.tableData.splice(index, 1);
            })
            .catch(() => {});
        },
        //新增月份价格
        addguestroommetnod() {
             this.addVisible = true 
        },
        
        addmenuFun() {
          console.log(this.addmonthData);
          post_room_price_list(this.addmonthData).then((res) =>{
               console.log(res);
               if(res.code == 1){
                    this.$message.success(res.msg);
                    this.addVisible = false
                    this.getData()
               }else{
                    this.$message.error(res.msg)
               }
          })
        },
        // 编辑操作
        handleEdit(index, row) {
            this.editVisible = true;
            this.editmonthData = row;
            this.editID = row.id
        },
        // 保存编辑
        saveEdit() {
          var editmonthData={
               type:"edit",
               id:this.editID,
               room_id:this.$route.query.id,
               month:this.editmonthData.month,
               old_price:this.editmonthData.old_price,
               price:this.editmonthData.price,
               mon:this.editmonthData.mon,
               tues:this.editmonthData.tues,
               wed:this.editmonthData.wed,
               thur:this.editmonthData.thur,
               fri:this.editmonthData.fri,
               sat:this.editmonthData.sat,
               sun:this.editmonthData.sun
            };
            console.log(this.editmonthData);
            
          post_room_price_list(editmonthData).then((res) => {
          if(res.code == 1){
               this.$message.success(res.msg);
               this.editVisible = false;
               this.getData()
          }else{
               this.$message.error(res.msg);

          }
          })
        },
        // 分页导航
        handlePageChange(val) {
          //   this.$set(this.query, "pageIndex", val);
            // this.getData();
        },
          back() {
               this.$router.go(-1); //返回上一层
          },
    }
};
</script>

<style scoped>
.title_01{
     margin-bottom: 20px;
}
.handle-box {
    margin-bottom: 77px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    margin-right: 10px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr9{float: left;}
.mr10 {
    margin-right: 10px;
    float: right;
}
.mr11{
    float: right;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
